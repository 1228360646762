import {
  Component,
  createSignal,
  createMemo,
  Show,
  Switch,
  Match,
  createEffect,
  Suspense,
  startTransition,
} from "solid-js";
import { debounce } from "@solid-primitives/scheduled";
import { createAsync } from "@solidjs/router";
import { useLocationSuggestions } from "~/services/mapbox";
import MapGL from "solid-map-gl";
import { Title } from "@solidjs/meta";
import { createForm } from "@felte/solid";
import * as z from "zod";
import { validator } from "@felte/validator-zod";
import Body from "~/components/Body";
import Breadcrumb from "~/components/Breadcrumb";
import HeaderWithContent from "~/components/HeaderWithContent";
import { envelope } from "solid-heroicons/solid";
import BannerInnerContent from "~/components/BannerInnerContent";
import "mapbox-gl/dist/mapbox-gl.css";
import { TextFieldInput, SelectBox } from "~/components/inputs";
import { getRegionsByCountryCode } from "~/utils/countries-states";
import { MetaDetails } from "~/components/utility";
import { MapboxAPIResponse } from "~/services/mapbox/types";
import { Icon } from "solid-heroicons";
import { checkCircle, xCircle } from "solid-heroicons/outline";

type AddressDetail = {
  Recipient: string;
  Number: string;
  Street: string;
  Street2: string;
  City: string;
  Region: string;
  Country: string;
  PostalCode: string;
  Phone: string;
  Email?: string;
  isBusinessAddress?: boolean;
};

const CountryStateSelector: Component<{
  countrySetter: (country: string) => void;
  regionSetter: (region: string) => void;
  countryError: string | string[] | undefined | null;
  regionError: string | string[] | undefined | null;
}> = (props) => {
  const [country, setCountry] = createSignal<string>();
  const [_, setRegion] = createSignal<string>();
  const regionsList = createMemo(() => {
    if (!country()) return [];
    return getRegionsByCountryCode(country() as string).map((region) => ({
      label: region?.name,
      value: region?.abbreviation,
    }));
  });

  return (
    <div class="grid md:grid-cols-2 gap-2">
      <SelectBox
        options={[
          { label: "Canada", value: "CA" },
          { label: "United States", value: "US" },
        ]}
        label="Country"
        background="bg-white"
        placeholder="Select country"
        onChange={(option) => {
          props.countrySetter(option?.value as string);
          setCountry(option?.value as string);
        }}
        error={props.countryError}
        validationState={props.countryError ? "invalid" : "valid"}
      />
      <SelectBox
        options={regionsList()}
        label="State/Province"
        background="bg-white"
        disabled={!country()}
        placeholder="Select region"
        onChange={(option) => {
          props.regionSetter(option?.value as string);
          setRegion(option?.value as string);
        }}
        error={props.regionError}
        validationState={props.regionError ? "invalid" : "valid"}
      />
    </div>
  );
};

const RetailerPage: Component = () => {
  const [sent, setSent] = createSignal<string | undefined>();
  const [coords, setCoords] = createSignal([
    -79.5550284269892, 43.771864128785694,
  ]);

  const reqNonEmpty = (max?: number) => {
    return z
      .string()
      .nonempty({ message: "This field cannot be empty" })
      .max(max ?? 40, {
        message: `Must be less than ${max ?? "40"} characters long.`,
      });
  };

  const addressSchema = z.object({
    Phone: reqNonEmpty(30),
    Number: reqNonEmpty(10),
    Street: reqNonEmpty(),
    Street2: z.string().max(40),
    City: reqNonEmpty(),
    Country: reqNonEmpty(2),
    Region: reqNonEmpty(3),
    PostalCode: reqNonEmpty(10),
  });

  const schema = z
    .object({
      LegalName: reqNonEmpty(150),
      FoundedIn: reqNonEmpty(10),
      Longitude: z.number(),
      Latitude: z.number(),
      CompanyDetails: addressSchema,
      ContactFirstName: reqNonEmpty(),
      ContactLastName: reqNonEmpty(),
      ContactPhone: reqNonEmpty(30),
      ShippingDetails: addressSchema.and(
        z.object({
          Email: reqNonEmpty(241).email(),
          Recipient: reqNonEmpty(),
        })
      ),
      OrderAcknowledgeEmail: reqNonEmpty(241).email(),
      InvoiceEmail: reqNonEmpty(241).email(),
      MonthlyStatementEmail: reqNonEmpty(241).email(),
      WebUserEmail: reqNonEmpty(241).email(),
      BusinessInformation: z.object({
        Storefront: z.string(),
        CornerSampleDisplay: z.string(),
        RetailSector: z.string(),
        Employees: z.string(),
        AnnualRevenue: z.string(),
      }),
    })
    .required();

  const {
    form,
    isSubmitting,
    data,
    reset: resetForm,
    setData,
    isValid,
    errors,
    isDirty,
  } = createForm<{
    LegalName: string;
    FoundedIn: string;
    Website: string;
    ContactFirstName: string;
    ContactLastName: string;
    ContactPhone: string;
    TaxNumber: string;
    ResaleID: string;
    CompanyDetails: AddressDetail;
    ShippingDetails: AddressDetail;
    OrderAcknowledgeEmail: string;
    InvoiceEmail: string;
    MonthlyStatementEmail: string;
    WebUserEmail: string;
    Latitude: number | undefined;
    Longitude: number | undefined;
    BusinessInformation: {
      Storefront: string;
      CornerSampleDisplay: string;
      RetailSector: string;
      Employees: string;
      AnnualRevenue: string;
      Notes: string;
    };
  }>({
    extend: validator({ schema }),
    onSubmit: async (values) => {
      setSent(undefined);
      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/partners/apply`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );
        if (response.ok) {
          setSent("success");
          resetForm();
        } else {
          const errorText = await response.text();
          throw new Error(
            `Request failed with status code ${response.status}. Error: ${errorText}`
          );
        }
      } catch (err) {
        console.log(err);
        setSent("error");
      }
    },
  });
  form;

  const [query, setQuery] = createSignal<string>("");
  const setQueryDebounced = debounce((value: string) => {
    startTransition(() => setQuery(value));
  }, 500);

  const constructedQueryString = createMemo(() => {
    return [
      data("CompanyDetails.Number"),
      data("CompanyDetails.Street"),
      data("CompanyDetails.City"),
      data("CompanyDetails.Region"),
      data("CompanyDetails.PostalCode"),
      data("CompanyDetails.Country"),
    ]
      .filter(Boolean)
      .join(" ");
  });

  createEffect(() => {
    setQueryDebounced(constructedQueryString());
  });

  const suggestions = createAsync(
    async () => {
      const results = await useLocationSuggestions(query());
      return results.features as MapboxAPIResponse["features"];
    },
    { initialValue: [] as MapboxAPIResponse["features"] }
  );

  return (
    <>
      <Title>Romamoulding.com - Become a Retailer</Title>
      <MetaDetails
        title="Become a Retailer"
        description="Looking to carry Roma products? Become a Roma Partner!"
        keywords="Partner, Retailer"
      />
      <Body>
        <div class="py-8">
          <BannerInnerContent
            bannerImage="https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2F8785959c7aa9475faad94a272251f80e"
            subheader=""
            header="Become a Partner"
            bgFilter="Darken-1"
            headerTextSize="Large"
            contentPosition="Center"
          />
          <Breadcrumb
            breadcrumbs={[
              { pageTitle: "Home", url: "/" },
              { pageTitle: "Support", url: "/support" },
              { pageTitle: "Become a Partner", url: "/support/partner" },
            ]}
          />
          <div class="my-20">
            <HeaderWithContent
              heading="Grow your business with our brand"
              bodyContent="We’re excited to be a part of your business! Please fill out the form below and one of our experienced business specialists will promptly get back with you to assist you finding the solutions that are best for you, your business, and your unique needs."
            />
          </div>
          <div class="w-full bg-roma-grey mx-auto">
            <Show when={sent() !== "success"}>
              <form use:form class="w-full max-w-2xl mx-auto">
                <fieldset
                  disabled={isSubmitting()}
                  class="w-full space-y-10 mx-auto px-5 py-20"
                >
                  <section class="flex flex-col gap-5 sm:px-5">
                    <div>
                      <p class="text-sm mb-5">
                        Fill out the following details to start your partner
                        application:
                      </p>
                    </div>
                    <h3 class="text-xl font-bold">Company Details</h3>
                    <TextFieldInput
                      label="Legal Business Name"
                      name="LegalName"
                      error={errors().LegalName}
                      validationState={errors().LegalName ? "invalid" : "valid"}
                      placeholder="Business or corporation name"
                      required
                    />
                    <TextFieldInput
                      label="Year of Founding"
                      type="text"
                      name="FoundedIn"
                      error={errors().FoundedIn}
                      validationState={errors().FoundedIn ? "invalid" : "valid"}
                      placeholder="Year of incorporation"
                      required
                    />
                    <TextFieldInput
                      label="Website"
                      name="Website"
                      error={errors().Website}
                      validationState={errors().Website ? "invalid" : "valid"}
                      placeholder="https://www.example.com"
                      required
                    />
                    <TextFieldInput
                      label="Phone Number"
                      name="CompanyDetails.Phone"
                      error={errors().CompanyDetails?.Phone}
                      validationState={
                        errors().CompanyDetails?.Phone ? "invalid" : "valid"
                      }
                      type="phone"
                      placeholder="+1 (555) 987-6543"
                    />
                    <div class="space-y-2">
                      <div class="grid md:grid-cols-4 gap-2">
                        <div class="md:col-span-1">
                          <TextFieldInput
                            label="Number"
                            name="CompanyDetails.Number"
                            error={errors().CompanyDetails?.Number}
                            validationState={
                              errors().CompanyDetails?.Number
                                ? "invalid"
                                : "valid"
                            }
                            placeholder="Number"
                            required
                          />
                        </div>
                        <div class="md:col-span-3">
                          <TextFieldInput
                            label="Street"
                            name="CompanyDetails.Street"
                            error={errors().CompanyDetails?.Street}
                            validationState={
                              errors().CompanyDetails?.Street
                                ? "invalid"
                                : "valid"
                            }
                            placeholder="Street Address"
                            required
                          />
                        </div>
                      </div>
                      <TextFieldInput
                        name="CompanyDetails.Street2"
                        error={errors().CompanyDetails?.Street2}
                        validationState={
                          errors().CompanyDetails?.Street2 ? "invalid" : "valid"
                        }
                        placeholder="Apt, suite, bldg, etc. (Optional)"
                      />
                    </div>
                    <TextFieldInput
                      label="City / Town"
                      name="CompanyDetails.City"
                      error={errors().CompanyDetails?.City}
                      validationState={
                        errors().CompanyDetails?.City ? "invalid" : "valid"
                      }
                      required
                    />
                    <div class="grid md:grid-cols-3 gap-2">
                      <div class="col-span-2">
                        <CountryStateSelector
                          countrySetter={(country) => {
                            startTransition(() =>
                              setData("CompanyDetails.Country", country)
                            );
                          }}
                          countryError={errors().CompanyDetails?.Country}
                          regionSetter={(region) => {
                            startTransition(() =>
                              setData("CompanyDetails.Region", region)
                            );
                          }}
                          regionError={errors().CompanyDetails?.Region}
                        />
                      </div>
                      <TextFieldInput
                        label="Zip / Postal Code"
                        name="CompanyDetails.PostalCode"
                        error={errors().CompanyDetails?.PostalCode}
                        validationState={
                          errors().CompanyDetails?.PostalCode
                            ? "invalid"
                            : "valid"
                        }
                        required
                      />
                    </div>
                    <Suspense>
                      <Show when={suggestions() && suggestions().length > 0}>
                        <SelectBox
                          background="bg-white"
                          label="Please Select Matching Address"
                          options={
                            suggestions()
                              ? suggestions().map((item) => {
                                  return {
                                    label: item.properties.full_address,
                                    value: item.geometry.coordinates.join(","),
                                  };
                                })
                              : []
                          }
                          onChange={(option) => {
                            const arr = (option?.value as string)
                              ?.split(",")
                              .map((x) => +x);
                            setCoords(arr);
                            setData("Latitude", arr[0]);
                            setData("Longitude", arr[1]);
                          }}
                          error={errors().Latitude || errors().Longitude}
                        />
                      </Show>
                    </Suspense>
                    <MapGL
                      class="h-[20vh] rounded-md border-roma-gray border"
                      viewport={{
                        center: coords(),
                        zoom: 10,
                      }}
                      options={{
                        projection: "mercator",
                        dragPan: true,
                        doubleClickZoom: true,
                        scrollZoom: false,
                        interactive: true,
                        accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
                        style: "mb:light",
                      }}
                    />
                  </section>
                  <hr />
                  <section class="flex flex-col gap-5 sm:px-5">
                    <h3 class="text-xl font-bold">Contact Details</h3>
                    <TextFieldInput
                      label="Contact First Name"
                      name="ContactFirstName"
                      error={errors().ContactFirstName}
                      validationState={
                        errors().ContactFirstName ? "invalid" : "valid"
                      }
                      required
                    />
                    <TextFieldInput
                      label="Contact Last Name"
                      name="ContactLastName"
                      error={errors().ContactLastName}
                      validationState={
                        errors().ContactLastName ? "invalid" : "valid"
                      }
                      required
                    />
                    <TextFieldInput
                      label="Contact Phone Number"
                      name="ContactPhone"
                      error={errors().ContactPhone}
                      validationState={
                        errors().ContactPhone ? "invalid" : "valid"
                      }
                      required
                    />
                  </section>{" "}
                  <hr />
                  <Show when={data()?.CompanyDetails?.Country === "US"}>
                    <section class="flex flex-col gap-5 sm:px-5">
                      <h3 class="text-xl font-bold">Corporation Details</h3>
                      <TextFieldInput
                        label="Tax Number"
                        name="TaxNumber"
                        error={errors().TaxNumber}
                        validationState={
                          errors().TaxNumber ? "invalid" : "valid"
                        }
                        placeholder="Corporation's government provided tax number"
                      />
                      <TextFieldInput
                        label="Resale ID"
                        name="ResaleID"
                        error={errors().ResaleID}
                        validationState={
                          errors().ResaleID ? "invalid" : "valid"
                        }
                      />
                    </section>
                    <hr />
                  </Show>
                  <section class="flex flex-col gap-5 sm:px-5">
                    <h3 class="text-xl font-bold">Shipping Details</h3>
                    <TextFieldInput
                      label="Phone"
                      type="phone"
                      name="ShippingDetails.Phone"
                      error={errors().ShippingDetails?.Phone}
                      validationState={
                        errors().ShippingDetails?.Phone ? "invalid" : "valid"
                      }
                      placeholder="+1 (555) 987-6543"
                    />
                    <TextFieldInput
                      label="Email"
                      type="email"
                      name="ShippingDetails.Email"
                      error={errors().ShippingDetails?.Email}
                      validationState={
                        errors().ShippingDetails?.Email ? "invalid" : "valid"
                      }
                      placeholder="email@example.com"
                      icon={envelope}
                      required
                    />
                    <TextFieldInput
                      label="Business Name"
                      name="ShippingDetails.Recipient"
                      error={errors().ShippingDetails?.Recipient}
                      validationState={
                        errors().ShippingDetails?.Recipient
                          ? "invalid"
                          : "valid"
                      }
                      placeholder="Business or corporation name"
                      required
                    />
                    <div class="space-y-2">
                      <div class="grid md:grid-cols-4 gap-2">
                        <div class="md:col-span-1">
                          <TextFieldInput
                            label="Number"
                            name="ShippingDetails.Number"
                            error={errors().ShippingDetails?.Number}
                            validationState={
                              errors().ShippingDetails?.Number
                                ? "invalid"
                                : "valid"
                            }
                            placeholder="Number"
                            required
                          />
                        </div>
                        <div class="md:col-span-3">
                          <TextFieldInput
                            label="Street"
                            name="ShippingDetails.Street"
                            error={errors().ShippingDetails?.Street}
                            validationState={
                              errors().ShippingDetails?.Street
                                ? "invalid"
                                : "valid"
                            }
                            placeholder="Street Address"
                            required
                          />
                        </div>
                      </div>
                      <TextFieldInput
                        name="ShippingDetails.Street2"
                        error={errors().ShippingDetails?.Street2}
                        validationState={
                          errors().ShippingDetails?.Street2
                            ? "invalid"
                            : "valid"
                        }
                        placeholder="Apt, suite, bldg, etc. (Optional)"
                      />
                    </div>
                    <TextFieldInput
                      label="City / Town"
                      name="ShippingDetails.City"
                      error={errors().ShippingDetails?.City}
                      validationState={
                        errors().ShippingDetails?.City ? "invalid" : "valid"
                      }
                      required
                    />
                    <div class="grid md:grid-cols-3 gap-2">
                      <div class="col-span-2">
                        <CountryStateSelector
                          countrySetter={(country) => {
                            setData("ShippingDetails.Country", country);
                          }}
                          countryError={errors().ShippingDetails?.Country}
                          regionSetter={(region) => {
                            setData("ShippingDetails.Region", region);
                          }}
                          regionError={errors().ShippingDetails?.Region}
                        />
                      </div>
                      <TextFieldInput
                        label="Zip / Postal Code"
                        name="ShippingDetails.PostalCode"
                        error={errors().ShippingDetails?.PostalCode}
                        validationState={
                          errors().ShippingDetails?.PostalCode
                            ? "invalid"
                            : "valid"
                        }
                      />
                    </div>
                    <div>
                      <input
                        name="ShippingDetails.isBusinessAddress"
                        type="checkbox"
                        class="focus-roma"
                      />
                      <label
                        for="isBusiness"
                        class="inline-block ml-4 mb-2 text-sm font-bold"
                      >
                        This is a business address
                      </label>
                    </div>
                  </section>
                  <hr />
                  <section class="flex flex-col gap-5 sm:px-5">
                    <h3 class="text-xl font-bold">Contact Details</h3>
                    <TextFieldInput
                      label="Order Acknowledge Email"
                      name="OrderAcknowledgeEmail"
                      error={errors().OrderAcknowledgeEmail}
                      validationState={
                        errors().OrderAcknowledgeEmail ? "invalid" : "valid"
                      }
                      type="email"
                      icon={envelope}
                      required
                    />
                    <TextFieldInput
                      label="Invoice Email"
                      name="InvoiceEmail"
                      error={errors().InvoiceEmail}
                      validationState={
                        errors().InvoiceEmail ? "invalid" : "valid"
                      }
                      type="email"
                      icon={envelope}
                      required
                    />
                    <TextFieldInput
                      label="Monthly Statement Email"
                      name="MonthlyStatementEmail"
                      error={errors().MonthlyStatementEmail}
                      validationState={
                        errors().MonthlyStatementEmail ? "invalid" : "valid"
                      }
                      type="email"
                      icon={envelope}
                      required
                    />
                    <TextFieldInput
                      label="Web User Email"
                      name="WebUserEmail"
                      error={errors().WebUserEmail}
                      validationState={
                        errors().WebUserEmail ? "invalid" : "valid"
                      }
                      type="email"
                      icon={envelope}
                      required
                    />
                  </section>
                  <hr />
                  <section class="flex flex-col gap-5 sm:px-5">
                    <h3 class="text-xl font-bold">Business Information</h3>
                    <div class="flex flex-col space-y-2">
                      <label
                        for="details-storefront"
                        class="block ml-1 mb-1 text-sm font-bold"
                      >
                        Do you operate as a storefront?
                      </label>
                      <SelectBox
                        background="bg-white"
                        name="BusinessInformation.Storefront"
                        error={errors().BusinessInformation?.Storefront}
                        validationState={
                          errors().BusinessInformation?.Storefront
                            ? "invalid"
                            : "valid"
                        }
                        options={[
                          {
                            label: "Yes",
                            value: "Yes",
                          },
                          {
                            label: "No",
                            value: "No",
                          },
                        ]}
                        placeholder="Select option"
                        onChange={(option) =>
                          setData(
                            "BusinessInformation.Storefront",
                            option.value as string
                          )
                        }
                      />
                      <label
                        for="details-corner-sample-display"
                        class="block ml-1 mb-1 text-sm font-bold"
                      >
                        Do you display corner samples?
                      </label>
                      <SelectBox
                        background="bg-white"
                        name="BusinessInformation.CornerSampleDisplay"
                        error={
                          errors().BusinessInformation?.CornerSampleDisplay
                        }
                        validationState={
                          errors().BusinessInformation?.CornerSampleDisplay
                            ? "invalid"
                            : "valid"
                        }
                        options={[
                          {
                            label: "Yes",
                            value: "Yes",
                          },
                          {
                            label: "No",
                            value: "No",
                          },
                        ]}
                        placeholder="Select option"
                        onChange={(option) =>
                          setData(
                            "BusinessInformation.CornerSampleDisplay",
                            option.value as string
                          )
                        }
                      />
                      <br />
                      <SelectBox
                        background="bg-white"
                        name="BusinessInformation.RetailSector"
                        inlineTitle="Retail Sector:"
                        error={errors().BusinessInformation?.RetailSector}
                        validationState={
                          errors().BusinessInformation?.RetailSector
                            ? "invalid"
                            : "valid"
                        }
                        options={[
                          {
                            label: "Custom Framer",
                            value: "Custom Framer",
                          },
                          {
                            label: "Fine Art Gallery",
                            value: "Fine Art Gallery",
                          },
                          {
                            label: "Independent Artist",
                            value: "Independent Artist",
                          },
                          {
                            label: "Retailer",
                            value: "Retailer",
                          },
                          {
                            label: "Hospitality",
                            value: "Hospitality",
                          },
                          {
                            label: "Other",
                            value: "Other",
                          },
                        ]}
                        placeholder="Select option"
                        onChange={(option) =>
                          setData(
                            "BusinessInformation.RetailSector",
                            option.value as string
                          )
                        }
                      />
                      <SelectBox
                        background="bg-white"
                        name="BusinessInformaton.Employees"
                        inlineTitle="No. of Employees:"
                        error={errors().BusinessInformation?.Employees}
                        validationState={
                          errors().BusinessInformation?.Employees
                            ? "invalid"
                            : "valid"
                        }
                        options={[
                          {
                            label: "1-10",
                            value: "1-10",
                          },
                          {
                            label: "11-50",
                            value: "11-50",
                          },
                          {
                            label: "51-100",
                            value: "51-100",
                          },
                          {
                            label: "101-1000",
                            value: "101-1000",
                          },
                          {
                            label: "1000+",
                            value: "1000+",
                          },
                        ]}
                        placeholder="Select option"
                        onChange={(option) =>
                          setData(
                            "BusinessInformation.Employees",
                            option.value as string
                          )
                        }
                      />
                      <SelectBox
                        background="bg-white"
                        name="BusinessInformation.AnnualRevenue"
                        inlineTitle="Approx. Revenue:"
                        error={errors().BusinessInformation?.AnnualRevenue}
                        validationState={
                          errors().BusinessInformation?.AnnualRevenue
                            ? "invalid"
                            : "valid"
                        }
                        options={[
                          {
                            label: "$2,500.00 - $10,000.00",
                            value: "$2,500.00 - $10,000.00",
                          },
                          {
                            label: "$10,001.00 - $25,000.00",
                            value: "$10,001.00 - $25,000.00",
                          },
                          {
                            label: "$25,001.00 - $100,000.00",
                            value: "$25,001.00 - $100,000.00",
                          },
                          {
                            label: "$100,001.00 - $500,000.00",
                            value: "$100,001.00 - $500,000.00",
                          },
                          { label: "$500,000.00+", value: "$500,000.00+" },
                        ]}
                        placeholder="Select option"
                        onChange={(option) =>
                          setData(
                            "BusinessInformation.AnnualRevenue",
                            option.value as string
                          )
                        }
                      />
                    </div>
                    <div>
                      <label
                        for="notes"
                        class="block ml-1 mb-2 text-sm font-bold"
                      >
                        What can we help you with?
                      </label>
                      <TextFieldInput
                        name="BusinessInformation.Notes"
                        error={errors().BusinessInformation?.Notes}
                        autoResize
                        type="textarea"
                        class="h-24"
                        placeholder="(Optional) Ask us a question or provide us with any additional infromation about your business that may assist us during your application process."
                      />
                    </div>
                  </section>
                  <div class="sm:px-5 flex flex-col gap-3">
                    <Show when={!isValid() && isDirty()}>
                      <div class="flex justify-center">
                        <p class="text-red-500 text-sm">
                          Please correct errors on page before submitting
                        </p>
                      </div>
                    </Show>
                    <button
                      type="submit"
                      disabled={isSubmitting()}
                      class="w-full py-2 border border-black bg-black hover:bg-transparent text-sm text-white hover:text-black font-bold rounded-full tracking-wide transition-colors disabled:cursor-not-allowed disabled:bg-grey-300 cursor-pointer"
                    >
                      Submit information
                    </button>
                    <button
                      class="text-sm ml-auto mr-2 text-roma-blue"
                      onClick={() => {
                        const ok = confirm(
                          "Are you sure you would like to reset the form?"
                        );
                        if (ok) {
                          resetForm();
                          setSent(undefined);
                        }
                      }}
                    >
                      Reset Form
                    </button>
                  </div>
                </fieldset>
              </form>
            </Show>
          </div>
          <div class=" max-w-2xl mx-auto mt-4 mb-10 bg-white">
            <Switch>
              <Match when={sent() === "success"}>
                <div class="py-4 px-8 mx-4 border border-gray-300 rounded-lg  bg-roma-grey shadow-sm flex flex-col gap-4 items-center justify-center">
                  <Icon
                    path={checkCircle}
                    class="w-20 h-20 text-success-green"
                  />
                  <p class="text-lg sm:text-2xl font-bold text-center">
                    Your submission was successful!
                  </p>
                  <p class="pb-4 text-center">
                    Thank you for your interest in becoming a partner!
                  </p>
                  <button
                    onClick={() => {
                      resetForm();
                      setSent(undefined);
                    }}
                    class="text-xs text-roma-blue ml-auto"
                  >
                    Reset Form
                  </button>
                </div>
              </Match>
              <Match when={sent() === "error"}>
                <div class="p-4 border border-gray-300 rounded-lg bg-red-50 flex flex-col items-center justify-center">
                  <Icon path={xCircle} class="w-20 h-20 text-red-600" />
                  <p>An error occurred while processing your submission.</p>
                  <p>
                    Please try again, or contact support at{" "}
                    <a
                      href="tel:18002632322"
                      rel="nofollow"
                      class="text-roma-blue"
                    >
                      1-800-263-2322
                    </a>
                  </p>
                </div>
              </Match>
            </Switch>
          </div>
        </div>
      </Body>
    </>
  );
};

export default RetailerPage;
